/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-26 09:51:22
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-05 10:55:41
 */
export default {
  courseCreate: '/course/manage/course/create',
  courseList: '/course/manage/courses/list',
  courseInfo: '/course/manage/course',
  courseUpdate: '/course/manage/course/update',
  courseStatus: '/course/manage/course/status',
  courseNav: '/course/manage/product-category/list',
  courseShopList: '/course/manage/product/list',
  courseShopInfo: '/course/manage/product/info',
  courseBuyList: '/course/manage/product/buy/course/list',
  courseBuyListByYears: '/course/manage/product/buy/course/listByYears',
  courseBuyListSelect: '/course/manage/product/buy/course/toClass',
  courseSections: '/course/manage/courses/sections', // 课程章节
  courseVideo: '/course/manage/course/section/video', // 课程可添加章节
  courseSectionSubmit: '/course/manage/course/sections/submit', // 提交课程章节
  courseVideoAdd: '/course/manage/video/add', // 添加视频资源
  courseVideoUpdate: '/course/manage/video/update', // 修改视频资源
  courseResourses: '/course/manage/resourses/page', // 视频资源列表
  uploadMetaDataUrl: '/third/api/huaWeiUpload/uploadMetaDataUrl', // 华为基于URL视频上传
  videoInfo: '/course/manage/video/info', // 视频资源详情
  courseDel: '/course/manage/course/delete', //课程删除
  courseCopy: '/course/manage/courses/copy', //复制课程
  resoursesDel: '/course/manage/resourses/remove', //课程资源删除
  groupList: '/course/manage/course/group/list', //视频资源分组列表
  delGroupList: '/course/manage/course/group/delete', //视频资源分组删除
  addGroup: '/course/manage/course/group/create', //视频资源分组添加
  editGroup: '/course/manage/course/group/edit', //视频资源分组编辑
  videoPlayUrl_huawei: '/third/api/huaWeiUpload/getVideoInfo',
  videoPlayUrl_ksb: '/course/identify/ksb/course/query/CourseChapterLiveUrl',
  videoPlayUrl_ali: '/third/api/aliUpload/getVideoPlayUrl',
  videoPlayInfo: '/course/video/play/info',
  collectCourse: '/course/manage/courses/addOrgConllectionInfo',
  internalTeacherList: '/course/identify/internalTrainer/getList',
  // 公开课
  publicCourseList: '/course/manage/openCourse/page',
  publicCourseAdd: '/course/manage/openCourse/add',
  publicCourseRemove: '/course/manage/openCourse/delete',
  publicCourseStatusUpdate: '/course/manage/openCourse/updateRecommend',
  publicCourseScoreUpdate: '/course/manage/openCourse/updateStudyScore',
  publicCourseStudyLog: '/course/manage/open/course/study/statistics/page',
  publicCourseLogOrgDropList:
    '/course/manage/open/course/study/statistics/org/course/dropdown/list',
  publicCourseLogStudentDropList:
    '/course/manage/open/course/study/statistics/org/student/dropdown/list',
  publicCourseLogExport: '/course/manage/open/course/study/statistics/export',
  //书院课程库
  courseWantLearn: '/course/manage/want/learn/statistics/page',
  courseBuyrecord: '/course/manage/buy/course/list',
  backMoneyApply: '/course/manage/buy/course/refund/apply',
  courseUseList: '/course/manage/buy/course/used/list',
  courseRecommond: '/course/intro/product/recommond',
  shopcartAdd: '/course/manage/shopcart/product/add',
}
