/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-26 09:52:01
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-05 10:59:30
 */
import config from './config/course'
import axios from '@/utils/request'
export const courseCreate = params => axios.post(config.courseCreate, params) //
export const courseList = params => axios.post(config.courseList, params) //
export const courseInfo = params => axios.post(config.courseInfo, params) //
export const courseUpdate = params => axios.post(config.courseUpdate, params) //
export const courseStatus = params => axios.post(config.courseStatus, params) //
export const courseNav = params => axios.post(config.courseNav, params) //
export const courseShopList = params => axios.post(config.courseShopList, params) //
export const courseShopInfo = params => axios.post(config.courseShopInfo, params) //
export const courseBuyList = params => axios.post(config.courseBuyList, params) //
export const courseBuyListByYears = params => axios.post(config.courseBuyListByYears, params) //
export const courseBuyListSelect = params => axios.post(config.courseBuyListSelect, params) //
export const courseSections = params => axios.post(config.courseSections, params) //
export const courseVideo = params => axios.post(config.courseVideo, params) //
export const courseSectionSubmit = params => axios.post(config.courseSectionSubmit, params) //
export const courseVideoAdd = params => axios.post(config.courseVideoAdd, params) //
export const courseVideoUpdate = params => axios.post(config.courseVideoUpdate, params) //
export const courseResourses = params => axios.post(config.courseResourses, params) //
export const uploadMetaDataUrl = params => axios.post(config.uploadMetaDataUrl, params) //
export const videoInfo = params => axios.post(config.videoInfo, params) //
export const courseDel = params => axios.post(config.courseDel, params) //
export const courseCopy = params => axios.post(config.courseCopy, params) //
export const resoursesDel = params => axios.post(config.resoursesDel, params) //
export const videoPlayUrl_huawei = params =>
  axios.post(`${config.videoPlayUrl_huawei}/${params.vid}`) // 获取播放地址
export const videoPlayUrl_ksb = params => axios.post(config.videoPlayUrl_ksb, params) // 获取播放地址-课师宝
export const videoPlayUrl_ali = params => axios.post(`${config.videoPlayUrl_ali}/${params.vid}`) // 获取播放地址-阿里
export const videoPlayInfo = params => axios.post(config.videoPlayInfo, params) // 获取播放地址
export const collectCourse = params => axios.post(config.collectCourse, params) // 收藏
export const internalTeacherList = params => axios.post(config.internalTeacherList, params) // 内训师列表
export const groupList = params => axios.post(config.groupList, params)
export const delGroupList = params => axios.post(config.delGroupList, params)
export const addGroup = params => axios.post(config.addGroup, params)
export const editGroup = params => axios.post(config.editGroup, params)
export const publicCourseList = params => axios.post(config.publicCourseList, params)
export const publicCourseAdd = params => axios.post(config.publicCourseAdd, params)
export const publicCourseRemove = params => axios.post(config.publicCourseRemove, params)
export const publicCourseStatusUpdate = params =>
  axios.post(config.publicCourseStatusUpdate, params)
export const publicCourseScoreUpdate = params => axios.post(config.publicCourseScoreUpdate, params)
export const publicCourseStudyLog = params => axios.post(config.publicCourseStudyLog, params)
export const publicCourseLogOrgDropList = params =>
  axios.post(config.publicCourseLogOrgDropList, params)
export const publicCourseLogStudentDropList = params =>
  axios.post(config.publicCourseLogStudentDropList, params)
export const publicCourseLogExport = params =>
  axios.post(config.publicCourseLogExport, params, { responseType: 'blob' })
//书院课程库
export const courseWantLearn = params => axios.post(config.courseWantLearn, params)
export const courseBuyrecord = params => axios.post(config.courseBuyrecord, params)
export const backMoneyApply = params => axios.post(config.backMoneyApply, params)
export const courseUseList = params => axios.post(config.courseUseList, params)
export const courseRecommond = params => axios.post(config.courseRecommond, params)
export const shopcartAdd = params => axios.post(config.shopcartAdd, params)
