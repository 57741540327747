<template>
  <div class="public-course">
    <table-list
      title="公开课程列表"
      :loading="loading"
      :data="courseList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true, fixScrollTable: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加课程',
    method: _this.handleAdd,
  },
  {
    label: '学习记录',
    method: _this.handleLog,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'courseName',
    placeholder: '请输入课程名称',
  },
  {
    label: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播' },
      { value: 1, label: '直播' },
    ],
  },
  {
    label: '课程状态',
    type: 'select',
    prop: 'recommendStatus',
    children: [
      { value: 1, label: '推荐' },
      { value: 2, label: '未推荐' },
    ],
  },
  // {
  //   label: '上传时间',
  //   type: 'picker',
  //   prop: 'createTime',
  // },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'pic',
    label: '课程封面',
    minWidth: 150,
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'height: 54px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: 250,
  },
  {
    prop: 'courseType',
    label: '课程类型',
    minWidth: 80,
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
  },
  {
    prop: 'teacherName',
    label: '任课老师',
    minWidth: 120,
  },
  {
    prop: 'studyScore',
    label: '学分',
    minWidth: 170,
    render: (h, { row }) => {
      return [
        h(
          'div',
          {
            on: {
              mouseenter: () => {
                _this.$set(row, 'editShow', true)
              },
              mouseleave: () => {
                _this.$set(row, 'editShow', false)
              },
            },
            style: {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            },
          },
          row.editable
            ? [
                h('el-input-number', {
                  props: {
                    size: 'small',
                    value: row.studyScoreTemp,
                    max: 99,
                  },
                  style: {
                    width: '50px',
                    height: '36px',
                  },
                  on: {
                    input: val => {
                      row.studyScoreTemp = val
                    },
                  },
                }),
                h(
                  'span',
                  {
                    style: { color: '#FF7B33', marginLeft: '29px' },
                    on: {
                      click: () => {
                        _this.$set(row, 'editable', false)
                        _this.handleScoreChange(row)
                      },
                    },
                  },
                  '保存',
                ),
                h(
                  'span',
                  {
                    style: { color: '#979797', marginLeft: '12px' },
                    on: {
                      click: () => {
                        _this.$set(row, 'editable', false)
                        row.studyScoreTemp = row.studyScore
                      },
                    },
                  },
                  '取消',
                ),
              ]
            : [
                h('span', row.studyScore),
                row.editShow
                  ? h(
                      'span',
                      {
                        style: { color: '#FF7B33', marginLeft: '29px' },
                        on: {
                          click: () => {
                            _this.$set(row, 'editable', true)
                          },
                        },
                      },
                      '修改',
                    )
                  : null,
              ],
        ),
      ]
    },
  },
  {
    prop: 'chapterCount',
    label: '章节数',
    sortable: true,
    minWidth: 100,
    align: 'right',
  },
  {
    prop: 'durationTotal',
    label: '课程时长（分钟）',
    sortable: true,
    minWidth: 120,
    align: 'right',
  },
  {
    prop: 'recommendStatus',
    label: '推荐状态',
    minWidth: 100,
    render: (h, { row }) => {
      return [
        h('el-switch', {
          props: { value: row.recommendStatus == 1 ? true : false },
          on: {
            change: async () => {
              console.log(row)
              await _this.handleStatusChange(row)
            },
          },
        }),
      ]
    },
  },
]
import TableList from '@/components/TableList'
import {
  publicCourseList,
  publicCourseRemove,
  publicCourseStatusUpdate,
  publicCourseScoreUpdate,
} from '@/api/course'
import to from 'await-to'
export default {
  name: 'PublicCourse',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      loading: false,
      courseList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    async getCourse() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(publicCourseList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list.map(item => ({
        ...item,
        studyScoreTemp: item.studyScore,
      }))
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.createTime) {
        queryParams.startTime = queryParams.createTime[0]
        queryParams.endTime = queryParams.createTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      queryParams.createTime = undefined
      this.queryParams = queryParams
      this.getCourse()
    },
    // 新增
    handleAdd() {
      this.$router.push('/course/public/add')
    },
    // 学习记录
    handleLog() {
      this.$router.push('/course/public/log')
    },
    // 编辑
    handleEdit({ id }) {
      this.$router.push(`/course/public/edit/${id}`)
    },
    async handleScoreChange(row) {
      const { openCourseId, studyScoreTemp } = row
      const [, err] = await to(
        publicCourseScoreUpdate({ openCourseId, studyScore: studyScoreTemp }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('保存成功')
      row.studyScore = studyScoreTemp
      // this.getCourse()
    },
    async handleStatusChange(row) {
      const { openCourseId, recommendStatus } = row
      const [, err] = await to(
        publicCourseStatusUpdate({ openCourseId, recommendStatus: recommendStatus == 1 ? 2 : 1 }),
      )
      if (err) return this.$message.warning(err.msg)
      row.recommendStatus = recommendStatus == 1 ? false : true
      // this.$message.success('删除成功')
      // this.getCourse()
    },
    // 删除
    async handleDelete() {
      if (!this.selectCourse.length) return this.$message.warning('请先选择要删除的公开课')
      let openCourseIds = []
      this.selectCourse.map(item => {
        openCourseIds.push(item.openCourseId)
      })
      this.$confirm('确认删除公开课吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(publicCourseRemove({ openCourseIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourse()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectCourse = val
      console.log(val)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourse()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourse()
    },
  },
}
</script>
<style lang="scss" scoped>
.public-course {
  ::v-deep .el-table {
    .el-input {
      input {
        padding: 0 5px;
        width: 50px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #c7c7c7;
        border-radius: 0;
      }
    }
    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
